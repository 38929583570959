<script setup>
      import { ref } from 'vue';
      import { fr } from "date-fns/locale";
      import { formatDistanceToNow } from "date-fns";
      const props = defineProps(['data', 'theme', 'logo']);
      const open = ref(false);
      const data = props.data;
      const theme = props.theme;
</script>
<template>
      <div :class="`${theme === 'dark' ? 'border-white' : 'border-bleu'} menu flex items-center pointer-events-auto border text-white cursor-pointer transition-all duration-500 group hover:bg-orange z-[8889]`"
            @click="open = !open">
            <span
                  :class="`${theme === 'dark' ? 'text-white' : 'text-bleu'} px-4 uppercase text-xs sm:text-sm sm:tracking-[3px] font-medium`">menu</span>
            <span class="flex bouton w-7 h-7 bg-orange relative">
                  <span
                        :class="`trait ${theme === 'dark' ? 'bg-bleu' : 'bg-white'} w-1/2 absolute left-0 top-1/2 h-[2px] group-hover:translate-x-full transition-all duration-500 ease-out`"></span>
            </span>
      </div>
      <aside
            :class="`${open ? 'translate-y-0' : '-translate-y-full'} shadow-2xl pointer-events-auto fixed top-0 left-0 w-full z-[8888] max-h-screen overflow-auto transition-all duration-500 ease-out pt-10 lg:pt-12 pb-24 px-10 xl:pt-48 lg:pb-48  bg-bleu`">
            <div
                  class="container mx-auto h-full text-orange font-medium flex flex-col lg:flex-row items-stretch justify-center">
                  <div class="gauche basis-4/12 xl:basis-3/12 text-white h-full flex flex-col font-medium sm:pr-10">
                        <div class="top flex-auto">
                              <a href="/">
                                    <img :src="props.logo.src" loading="eager" alt="Logo CNC"
                                          class="w-32 h-32 object-contain" />
                              </a>
                              <div class="hidden lg:block text-base font-medium my-8" v-html="data.texte_de_gauche">
                              </div>
                              <div class="liens hidden lg:flex flex-col gap-3">
                                    <a href="/rejoindre"
                                          class="flex items-start gap-2 text-orange text-sm sm:text-base transition-colors leading-tight  hover:text-white">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="19.324" height="19.324"
                                                viewBox="0 0 19.324 19.324" class="translate-y-1">
                                                <path id="Tracé_59" data-name="Tracé 59"
                                                      d="M110.723.023V19.347h19.324V10.3h-9.662V9.065h9.662V.023Z"
                                                      transform="translate(-110.723 -0.023)" fill="#fff" />
                                          </svg>
                                          Pourquoi rejoindre <br />le réseau CNC
                                    </a>
                                    <a href="https://monespace.reseau-cnc.fr"
                                          class="flex items-start gap-2 text-orange text-sm sm:text-base transition-colors leading-tight hover:text-white">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="19.324" height="19.324"
                                                viewBox="0 0 19.324 19.324" class="translate-y-1">
                                                <path id="Tracé_59" data-name="Tracé 59"
                                                      d="M110.723.023V19.347h19.324V10.3h-9.662V9.065h9.662V.023Z"
                                                      transform="translate(-110.723 -0.023)" fill="#fff" />
                                          </svg>
                                          Se connecter à <br />mon espace CNC
                                    </a>
                              </div>
                        </div>
                        <div class="mt-8 sm:mt-28 reseaux flex-none flex lg:flex-col items-start justify-between gap-2">
                              <label>Suivez-nous sur <br />les réseaux sociaux</label>
                              <div
                                    class="icones border-t border-b border-slate-600 py-2 text-orange text-2xl flex gap-3">
                                    <a href="https://www.linkedin.com/showcase/continuum-num%C3%A9rique/"
                                          target="_blank" class="hover:text-white transition-colors"
                                          title="Retrouvez CNC sur LinkedIn">
                                          <i class="fa-brands fa-linkedin"></i>
                                    </a>
                              </div>
                        </div>
                  </div>
                  <div class="hidden xl:block basis-1/12"></div>
                  <div class="basis-8/12 lg:pt-32 xl:pt-0 xl:basis-5/12">

                        <nav role="menu" class="super-menu mt-10  lg:mt-0">
                              <ul>
                                    <li v-for="(item, index) in data.menu_principal" :key="`lien_principal_${index}`">
                                          <a :href="item.lien"
                                                class="flex flex-col group text-lg sm:text-xl lg:text-3xl font-medium text-orange py-2 lg:py-4 border-b-[1px] border-b-white/10 hover:font-semibold hover:text-orange transition-all">
                                                <label
                                                      class="line-clamp-1 transition-all duration-500 flex items-center gap-4">
                                                      <svg class="transition-all duration-500  -translate-x-full group-hover:translate-x-0"
                                                            xmlns="http://www.w3.org/2000/svg" width="31.916"
                                                            height="31.916" viewBox="0 0 31.916 31.916">
                                                            <path id="Tracé_58" data-name="Tracé 58"
                                                                  d="M110.723.023V31.939h31.916V17H126.682V14.957h15.958V.023Z"
                                                                  transform="translate(-110.723 -0.023)" fill="#fff" />
                                                      </svg>
                                                      <span
                                                            class="-translate-x-[48px] group-hover:translate-x-0 transition-transform duration-500">{{
                                                            item.label }}</span>
                                                </label>
                                                <div class="sub h-0 overflow-hidden group-hover:h-auto group-hover:transition-all group-hover:duration-700 group-hover:opacity-100 group-hover:translate-y-0 opacity-0 translate-y-2 text-white group-hover:py-4 text-sm font-normal "
                                                      v-if="item.texte?.length" v-html="item.texte"></div>
                                          </a>
                                    </li>
                              </ul>
                        </nav>
                        <nav role="menu" class="xl:hidden mt-10">
                              <ul>
                                    <li v-for="(item, index) in data.menu_secondaire" :key="`lien_principal_${index}`">
                                          <a :href="item.lien" :title="item.label"
                                                class="py-1 block font-normal text-sm lg:text-base lg:font-medium hover:text-white transition-all">
                                                {{ item.label }}
                                          </a>
                                    </li>
                              </ul>
                        </nav>
                  </div>
                  <div class="hidden xl:block basis-1/12"></div>
                  <div class="hidden xl:block basis-2/12">
                        <div v-if="data.evenement_a_la_une">
                              <a class="bg-white/10 px-4 block" :href="`/ressources/${data.evenement_a_la_une.slug}`"
                                    :title="`${data.evenement_a_la_une.titre}`">
                                    <div class="categorie uppercase text-white text-xs font-medium pt-4">évènement à la une</div>
                                    <h3 class="font-normal text-orange mt-10 text-xl">{{data.evenement_a_la_une.titre}}</h3>
                                    <div class="date text-white py-1 text-xs border-t border-orange/50 border-b my-2" >{{formatDistanceToNow(new
                                          Date(data.evenement_a_la_une.date_updated), { locale: fr, addSuffix: true })}}
                                    </div>
                                    <div class="image aspect-square relative -ml-4 -mr-4 mt-10">
                                          <img loading="eager"
                                                class="absolute top-0 left-0 object-cover object-center w-full h-full"
                                                :src="`https://cnc.directus.app/assets/${data.evenement_a_la_une.image_a_la_une}?fit=cover&width=400&quality=80&format=webp`"
                                                :alt="data.evenement_a_la_une.titre" />
                                    </div>
                              </a>
                        </div>
                        <nav role="menu" class="mt-10">
                              <ul>
                                    <li v-for="(item, index) in data.menu_secondaire" :key="`lien_principal_${index}`">
                                          <a :href="item.lien" :title="item.label"
                                                class="py-1 block font-normal text-sm lg:text-base  hover:text-white transition-all">
                                                {{ item.label }}
                                          </a>
                                    </li>
                              </ul>
                        </nav>
                  </div>
            </div>
      </aside>
</template>